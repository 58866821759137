import { App } from 'vue'

import Card from './Card.vue'
import Catalog from './Catalog.vue'

const register = (app: App<Element>): void => {
  app
    .component('restaurant-card', Card)
    .component('restaurant-catalog', Catalog)
}

export default {
  register,
}
