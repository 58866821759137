import { App } from 'vue'
import View from './ListView.vue'

const register = (app: App<Element>): void => {
  app
    .component('list-view', View)
}

export default {
  register,
}
