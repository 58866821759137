import { defineComponent as _defineComponent } from 'vue'
import { ref, onMounted } from 'vue'


export default /*@__PURE__*/_defineComponent({
  __name: 'ControlDescriptor',
  props: {
  component: {
    type: String,
    default: 'SimpleInput',
  },
  name: String,
  errors: {
    type: Object,
    default: () => ({}),
  },
  inputLabel: String,
  options: Array,
  trackBy: String,
  type: String,
  optionLabel: String,
  rules: {
    type: [String, Object],
    default: '',
  },
  labelComponent: {
    type: String,
    default: 'ControlLabel',
  },
  setInitialValue: {
    type: Boolean,
    default: true,
  },
  modelValue: {},
},
  emits: ['update:modelValue'],
  setup(__props, { expose: __expose, emit: __emit }) {
  __expose();

const props = __props

const emit = __emit

const inputValue = ref('')
const isRequired = ref(false)

if (typeof props.rules === 'object' && props.rules !== null) {
  isRequired.value = props.rules?.required || false
} else {
  isRequired.value = props.rules.includes('required')
}

const updateValue = (val: any) => {
  inputValue.value = val

  emit('update:modelValue', inputValue.value)
}

// onMounted(() => {
//   if (props.setInitialValue) {
//     inputValue.value = props.modelValue || ''
//   }
// })

const __returned__ = { props, emit, inputValue, isRequired, updateValue, ref, onMounted }
Object.defineProperty(__returned__, '__isScriptSetup', { enumerable: false, value: true })
return __returned__
}

})