import { App } from 'vue'
import { OnClickOutside } from '@vueuse/components'
import UiDropdown from './UiDropdown.vue'
import UiCropper from './UiCropper.vue'
import UiScrollToTop from './UiScrollToTop.vue'
import UiShare from './UiShare.vue'
import UiCopyButton from './UiCopyButton.vue'
import UiSidebarToggler from './UiSidebarToggler.vue'
import UiPrivacyPolicy from './UiPrivacyPolicy.vue'
import UiImageZoomer from './UiImageZoomer.vue'
import UiVideo from './UiVideo.vue'

const register = (app: App<Element>): void => {
  app
    .component('ui-click-outside', OnClickOutside)
    .component('ui-dropdown', UiDropdown)
    .component('ui-cropper', UiCropper)
    .component('ui-scroll-to-top', UiScrollToTop)
    .component('ui-share', UiShare)
    .component('ui-copy-button', UiCopyButton)
    .component('ui-sidebar-toggler', UiSidebarToggler)
    .component('ui-privacy-policy', UiPrivacyPolicy)
    .component('ui-image-zoomer', UiImageZoomer)
    .component('ui-video', UiVideo)
}

export default {
  register,
}
