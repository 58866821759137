import { optionsT } from '@router/index'

import { createRoutes as createSettingsRoutes } from './modules/settings/router'
import { createRoutes as createErrorsRoutes } from './modules/errors/router'

export function createRoutes(options: optionsT): any {
  return [
    ...createSettingsRoutes(options),
    ...createErrorsRoutes(options),
  ]
}
