import { defineComponent as _defineComponent } from 'vue'
import { ref } from 'vue'
import {
  productCatalogResource,
  productCatalogFiltersResource,
} from '@/services/catalog.service'
import { prefixLanguage } from '@/utils/urls'
import useGlobal from '@composables/useGlobal'


export default /*@__PURE__*/_defineComponent({
  __name: 'Catalog',
  props: {
  pagination: {
    type: Object,
  },
  cardsWrapperClass: {
    type: String,
  },
  label: {
    type: String,
  },
  categories: {
    type: Array,
    default: () => ([]),
  },
},
  setup(__props, { expose: __expose }) {
  __expose();

const props = __props

const { $t } = useGlobal()

const pluralForms = [
  $t('товар'),
  $t('товара'),
  $t('товаров'),
]

const sortValues = [
  { title: $t('Сначала дорогие'), label: 'expensive' },
  { title: $t('Сначала дешевые'), label: 'cheap' },
  { title: $t('По новизне'), label: 'novelty' },
  { title: $t('По рейтингу'), label: 'rank' },
]

const categoriesLabel = $t("Категории товаров")

const clearUrlPath = prefixLanguage('catalog')
const urlPath = prefixLanguage(`${clearUrlPath}/${props.label}`)

const __returned__ = { props, $t, pluralForms, sortValues, categoriesLabel, clearUrlPath, urlPath, ref, get productCatalogResource() { return productCatalogResource }, get productCatalogFiltersResource() { return productCatalogFiltersResource }, get prefixLanguage() { return prefixLanguage }, get useGlobal() { return useGlobal } }
Object.defineProperty(__returned__, '__isScriptSetup', { enumerable: false, value: true })
return __returned__
}

})