import {
  baseResource,
  createResource,
} from '@/resource/resource'
import { prefixAPI } from '@/resource/api'
import { prefixLanguage } from '@/utils/urls'
import { friendlyUrlGenerator } from '@/utils/friendly'

const PRODUCT_CATALOG_FILTERS = prefixAPI('/filter-attributes', 'catalog')

const FILTERS_LIST_URL_PREFIX = prefixLanguage('/catalog')

export const productCatalogResource = friendlyUrlGenerator([
  FILTERS_LIST_URL_PREFIX, 'ajax'
], baseResource)

export const productCatalogFiltersResource = friendlyUrlGenerator([
  PRODUCT_CATALOG_FILTERS, 'filters'
], baseResource)

