import { App, defineAsyncComponent } from 'vue'

const PrivacyPolicy = defineAsyncComponent(() => import('./PrivacyPolicy.vue'))
const Login = defineAsyncComponent(() => import('./Login.vue'))
const RegistrationCustomer = defineAsyncComponent(() => import('./RegistrationCustomer.vue'))
const RegistrationRestaurant = defineAsyncComponent(() => import('./RegistrationRestaurant.vue'))
const PasswordForgot = defineAsyncComponent(() => import('./PasswordForgot.vue'))
const PasswordSet = defineAsyncComponent(() => import('./PasswordSet.vue'))

const register = (app: App<Element>): void => {
  app
    .component('authentication-privacy-policy', PrivacyPolicy)
    .component('authentication-login', Login)
    .component('authentication-registration-customer', RegistrationCustomer)
    .component('authentication-registration-restaurant', RegistrationRestaurant)
    .component('authentication-password-forgot', PasswordForgot)
    .component('authentication-password-set', PasswordSet)
}

export default {
  register,
}
